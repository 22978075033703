<template>
  <v-container fluid>
    <Header />
    <v-container page id="contenuto">
      <h2>Accessibilità</h2>
      <p>
        Regione Piemonte pone grande attenzione al tema dell'accessibilità delle
        informazioni. Nell'intento di raggiungere il maggior numero di utenti
        senza escludere chi ha disabilità e chi dispone di dotazione tecnologica
        obsoleta, il sito web regionale è stato realizzato rispettando gli
        standard del W3C e i principi enunciati dalla legge 9 gennaio 2004, n°
        4, nonché i 22 requisiti dell'allegato A del Decreto Ministeriale 8
        luglio 2005.
      </p>
      <p>
        Consulta la
        <a
          href="https://form.agid.gov.it/view/f7092220-9b4e-11ef-b03b-3180bd28efda"
          target="_blank"
          rel="nofollow"
          >Dichiarazione di accessibilità rilasciata dall'Agenzia per l'Italia
          Digitale (AgID)</a
        >
      </p>
      <p>
        Le pagine sono sviluppate utilizzando tecnologie che permettono la
        separazione tra contenuto e impaginazione di un documento, consentendone
        una fruizione efficace, indipendentemente dal dispositivo tecnologico
        adottato e nella maniera più flessibile rispetto ai diversi dispositivi.
        Disabilitando i fogli di stile, viene visualizzata la versione testuale
        della pagina; inoltre, utilizzando uno smartphone o un tablet, l’aspetto
        grafico del sito viene automaticamente adattato alle ridotte dimensioni
        dello schermo, secondo le tecniche del responsive design.
      </p>
      <p>
        Per segnalare casi di mancata conformità ai requisiti di accessibilità,
        per richiedere informazioni e contenuti che siano stati esclusi
        dall'ambito di applicazione della Direttiva UE 2016/2102 scrivere a
        <a
          href="mailto:risanamento.atmosferico@regione.piemonte.it"
          rel="nofollow"
          >risanamento.atmosferico@regione.piemonte.it</a
        >
      </p>
      <p>Grazie, il vostro contributo è prezioso.</p>
    </v-container>
  </v-container>
</template>

<script>
import Header from "@/components/layout/Header";

export default {
  name: "Home",
  components: { Header }
};
</script>
